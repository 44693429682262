import BACKGROUND_MUSIC from '../assets/audio/background.mp3';
import MUSIC from '../assets/audio/iglu-music.mp3';

const AudioSource = {
  backgroundMusic: new Audio(BACKGROUND_MUSIC),
  music: new Audio(MUSIC)
};

AudioSource.backgroundMusic.volume = 0.4;
AudioSource.music.volume = 0.3;

function setMute(mute: boolean) {
  Object.values(AudioSource).forEach((audio) => {
    audio.muted = mute;
  });
}

function playAll() {
  Object.values(AudioSource).forEach((audio) => {
    play(audio);
  });
}

function play(audio: HTMLAudioElement, resume = false) {
  if (!resume) audio.currentTime = 0;
  audio.play();
}

function pause(audio: HTMLAudioElement) {
  audio.pause();
}

export { AudioSource, setMute, play, pause, playAll };
