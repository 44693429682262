import React from 'react';

interface TextBackgroundProps {
  children: React.ReactNode;
  backgroundImage: string;
  className?: string;
  imgClassName?: string;
}

const TextBackground: React.FC<TextBackgroundProps> = ({
  children,
  backgroundImage,
  className = '',
  imgClassName = ''
}) => {
  return (
    <div className={`relative ${className}`}>
      {children}
      <img
        className={`absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[-1] ${imgClassName}`}
        src={backgroundImage}
        alt=""
      />
    </div>
  );
};

export default TextBackground;
