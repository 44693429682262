export const API_URl =
  process.env.REACT_APP_BACKEND_ENV === 'production'
    ? 'https://admin.alpskisvizec.si/api'
    : 'https://api.iglu-development.peach-stats.com/api/';
export const API_TOKEN =
  process.env.REACT_APP_BACKEND_ENV === 'production'
    ? 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7ImVtYWlsIjoiYWRtaW5Ac3R5bGlmZi5jb20ifSwiZXhwIjoyMDAyMDIxNjQ5LCJpYXQiOjE2ODY2NjE2NDl9.UhMXhzkIj57c8UBRl0HzrCWr1V6jhwtG4WDLN3p1N_M'
    : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7ImVtYWlsIjoiZGV2ZWxvcGVyQHN0eWxpZmYuY29tIn0sImV4cCI6MTk5MDAwMTEwMCwiaWF0IjoxNjc4OTYxMTAwfQ.Zxos7ipAmF_uGs_tn2DRz32OYuGX43ZPLTq4yVUcWj8';
export const HTTP_TIMEOUT = 60000;

export const COLORS = {
  orange: '#F08015',
  lightOrange: '#eb9746'
};

export enum CAMERAS {
  INTRO_1 = 1,
  INTRO_2 = 2,
  JOURNEY = 3
}

export const FONTS = {
  primary: 'BigNoodleTitling',
  secondary: 'BigshotOne',
  nunito: 'Nunito'
};

export const ROUTES = {
  LANDING: '/',
  HIKING_GUIDE: '/gorski-specialist',
  ABOUT: '/o-nas',
  BASE_CAMP: '/bazni-tabor',
  PRODUCTS: '/izdelki'
};
