import React from 'react';

interface ContentWrapperProps {
  className?: string;
  children: React.ReactNode;
  navigationNegativeMargin?: boolean;
}

const ContentWrapper: React.FC<ContentWrapperProps> = ({ children, className = '', navigationNegativeMargin }) => {
  return (
    <div
      className={`relative flex flex-col flex-1 items-center max-w-[1200px] self-center ${className} ${
        navigationNegativeMargin ? 'mt-[-66px]' : ''
      }`}
    >
      {children}
    </div>
  );
};

export default ContentWrapper;
