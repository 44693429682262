import React, { ButtonHTMLAttributes } from 'react';
import carretLeft from '../../assets/images/carret_left.png';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  backgroundColor?: string;
  leftImage?: string;
  rightImage?: string;
  variant?: 'normal' | 'basic' | 'back' | 'skip' | 'big';
  fontSize?: number;
}

const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  backgroundColor,
  leftImage,
  rightImage,
  className,
  variant = 'normal',
  fontSize,
  ...rest
}) => {
  const displayLeftArrow = variant === 'back';
  const displayRightArrow = variant === 'skip';
  const underlay = ['skip', 'back'].includes(variant);

  return (
    <button
      {...rest}
      type="button"
      onClick={onClick}
      className={`button ${variant} flex justify-center items-center text-h4 ${className}`}
    >
      {leftImage && <img src={leftImage} className="absolute left-0 h-full" alt="" />}
      {displayLeftArrow && <img className="mr-[12px] h-[20px]" src={carretLeft} alt="Arrow left" />}
      {children}
      {displayRightArrow && (
        <img className="ml-[12px] h-[20px] sm:h-[35px] rotate-180" src={carretLeft} alt="Arrow right" />
      )}
      {rightImage && <img src={rightImage} className="absolute right-0 h-full" alt="" />}
      {underlay && <div className="absolute bg-[rgba(0,0,0,0.75)] h-full w-full rounded-full blur-xl -z-[10]" />}
    </button>
  );
};

export default Button;
